body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-container {
  max-width: 600px; /* or whatever width you prefer */
  margin: 20px auto; /* centers the form in the middle of the page */
  padding: 20px; /* adds some spacing inside the container */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* optional: adds a subtle shadow for depth */
  border-radius: 8px; /* optional: rounds the corners */
  background-color: white; /* optional: sets a background color */
}



.form-container {
  max-width: 600px; /* or whatever width you prefer */
  margin: 0 auto; /* centers the form in the middle of the page */
  padding: 20px; /* adds some spacing inside the container */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* optional: adds a subtle shadow for depth */
  border-radius: 8px; /* optional: rounds the corners */
  background-color: white; /* optional: sets a background color */
}

.boldRow {
  font-weight: bold;
}

/* globalStyles.css */
.markdown p {
  font-size: 15px; /* Larger text for paragraphs */
}

.markdown h1 {
  font-size: 32px; /* Larger text for h1 headers */
}

.markdown h2 {
  font-size: 24px; /* Larger text for h2 headers */
}

.markdown li {
  font-size: 16px; /* Larger text for h2 headers */
}

.ant-carousel .slick-dots li button {
  background: #777;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #777;
}

.link-hover-effect {
  color: #222222 !important; /* Default color */
  text-decoration: none !important;
  font-size: 17px;
  font-weight: 700;
}

.link-hover-effect:hover {
  color: #777777 !important; /* Lighter color on hover */
}

.list-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
.list {
  list-style: none;
  padding: 0;
}
.list-item {
  margin-bottom: 10px;
}
.list-title {
  font-weight: bold;
  margin-bottom: 10px;
}
